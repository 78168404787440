import * as React from "react"
import styled from "styled-components"

import Header from "../components/Header"
import Footer from "../components/Footer"

import { Helmet } from 'react-helmet'
import { Link } from "gatsby"

const Container = styled.div`
  font-family: monospace;
`

const Main = styled.div`
  margin: 3rem auto;
  max-width: 960px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    max-width: 640px;
  }
  form {
    width: 100%;
    max-width: 240px;
  }
  input, textarea {
    margin: 0.4em 0;
    width: 100%;
  }
`


const SomethingMissingPage = () => {
  const mountainLakeImgSrc = "images/mountain_lake.jpg"
  return (
    <Container>
      <Helmet>
          <html lang='en' />
          <title>dogpark.fyi - san francisco's dog parks</title>
          <meta name='description' content="information about san francisco's dog parks" />
          <meta property='og:image' content={mountainLakeImgSrc} />
      </Helmet>
      <Main>
        <Header />
        <h3>Something Missing?</h3>
        <p>We're trying to collect the best info on dog parks in San Francisco. If you see something missing or have any suggestions let us know!</p>
        <form action="https://formspree.io/f/xqkgwlre" method="POST">
          <label>
            Your email<br/>
            <input type="text" name="_replyto" />
          </label><br/>
          <br/>
          <label>
            Suggestions, tips, or any feedback is greatly appreciated!<br/>
            <textarea name="message" rows="10"></textarea>
          </label><br/>
          <button type="submit">Send</button>
        </form>
      </Main>
      <Footer />
    </Container>
  )
}

export default SomethingMissingPage
